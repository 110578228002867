// Home

.main-wrapper--home {
    padding: 0;
}

.main__home {
    h1 {
        display: none;
    }

    .layer {
        h2 {
            font-weight: 800;
            font-size: 10vw;
            text-transform: uppercase;
            padding: 0 50px;
            text-shadow: 0 0 50px #000;
            margin-bottom: 20px;
    
            @include media-breakpoint-up(md) {
                font-size: 9vw;
            }
    
            @include media-breakpoint-up(lg) {
                font-size: 8vw;
            }
    
            @include media-breakpoint-up(xl) {
                @include font-size(110);
                @include line-height(90);
            }
    
            span {
                font-size: 6vw;
                @include media-breakpoint-up(md) {
                    font-size: 5vw;
                }
        
                @include media-breakpoint-up(lg) {
                    font-size: 4vw;
                }
                @include media-breakpoint-up(xl) {
                    @include font-size(50);
                }
            }
        }
        p {
            font-size: 3vw;
            text-shadow: 0 0 2px #666666;
            font-weight: 600;
            padding: 0 50px;

            @include media-breakpoint-up(sm) {
                font-size: 2vw;
                padding: 0 15%;
            }
            @include media-breakpoint-up(md) {
                font-size: 1.5vw;
                padding: 0 20%;
            }
            @include media-breakpoint-up(lg) {
                @include font-size(14);
                padding: 0 35%;
            }
        }
    }
}

.section {
    text-align:center;
    font-size: 3em;
    position: relative;
}

.myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    z-index: 3;
}

.layer {
    position: absolute;
    z-index: 4;
    width: 100%;
    left: 0;
    top: 43%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    color: $white;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    background: $white;
}