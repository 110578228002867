// Footer

.main__footer {
    padding: 5px 0;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-size(12);

        li {
            margin: 0 5px;
        }

        a, p {
            margin: 0;
            text-shadow: 0 0 2px $fifth-color;
            font-weight: 600;
            color: $white;
        }
    }
}

.main-wrapper--home {
    .main__footer {
        position: fixed;
        bottom: 30px;
        right: 30px;

        .container-fluid {
            padding: 0;
        }
    }
}