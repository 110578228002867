// Artiste

.main-wrapper--artist {
    .artist-picture {
        width: 100%;
        height: auto;
        margin: 30px 0;
    }
    .artist-video {
        margin-bottom: 30px;
        iframe, video {
            height: 50vw;

            @include media-breakpoint-up(lg) {
                height: 400px;
            }

            @include media-breakpoint-up(xl) {
                height: 500px;
            }
        }
    }

    .artist-social {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        a {
            width: 45px;
            color: $primary-color;
            margin: 0 20px;
        }

        .svg-inline--fa {
            fill: $primary-color;
            transition: all .2s ease-in;

            &:hover {
                fill: $white;
            }
        }
    }
}

.perturbator {
    .navbar .navbar-nav .nav-item:nth-child(1) .nav-link {
        color: $primary-color;
    }
}

.dan-terminus {
    .navbar .navbar-nav .nav-item:nth-child(2) .nav-link {
        color: $primary-color;
    }
}

.hypno5e {
    .navbar .navbar-nav .nav-item:nth-child(3) .nav-link {
        color: $primary-color;
    }
}

.gost {
    .navbar .navbar-nav .nav-item:nth-child(4) .nav-link {
        color: $primary-color;
    }
}