// Contact

.main-wrapper--contact {
    .main__header {
        .navbar-nav {
            .nav-item:last-child {
                .nav-link {
                    color: $primary-color;
                }
            }
        }
    }
}