// Page

.main-wrapper--page {
    .main__header {
        .navbar-nav {
            .nav-item.active {
                .nav-link {
                    color: $white;
                }
            }
        }
    }
    .main__page {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .page__header {
        margin-bottom: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $white;
    
        h1 {
            margin: 0;
            @include line-height(38);
            font-weight: 800;
            text-transform: uppercase;
        }
    }

    .page__content {
        h2 {
            @include font-size(24);
            margin-top: 50px;
            margin-bottom: 15px;
            text-transform: uppercase;
            font-weight: 800;
            color: $primary-color;
        }
        h3 {
            @include font-size(20);
            margin-top: 30px;
            margin-bottom: 15px;
            text-transform: uppercase;
            font-weight: 800;
            color: $white;
        }
        h4 {
            @include font-size(16);
            margin-top: 30px;
            margin-bottom: 15px;
            text-transform: uppercase;
            font-weight: 800;
            color: $primary-color;
        }
        p {
            @include font-size(16);
            margin-bottom: 10px;
        }
    }
}
