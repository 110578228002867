// Fonts

@font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: normal;
    src: url('../img/OpenSans-Light.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: italic;
    src: url('../img/OpenSans-LightItalic.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
    src: url('../img/OpenSans-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: italic;
    src: url('../img/OpenSans-Italic.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: normal;
    src: url('../img/OpenSans-SemiBold.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: italic;
    src: url('../img/OpenSans-SemiBoldItalic.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: normal;
    src: url('../img/OpenSans-Bold.ttf');
    font-display: swap;
}

 @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: italic;
    src: url('../img/OpenSans-BoldItalic.ttf');
    font-display: swap;
 }

 @font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-style: normal;
    src: url('../img/OpenSans-ExtraBold.ttf');
    font-display: swap;
 }

 @font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-style: italic;
    src: url('../img/OpenSans-ExtraBoldItalic.ttf');
    font-display: swap;
 }
 