// Global

.hello {
    outline: 1px solid red;
}

* {
    box-sizing: border-box;
}

html, body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: $main-typo;
    color: $white;
    background: $black;
}

.container-fluid {
    padding: 0 15px;
    max-width: 1600px;
    @include media-breakpoint-up(md) {
        padding: 0 60px;
    }
}

.container-center {
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
        padding: 0 15px;
    }

    @include media-breakpoint-up(lg) {
        width: 700px;
    }

    @include media-breakpoint-up(xl) {
        width: 900px;
    }
}

.main-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1 0 auto;
}

a {
    transition: all .2s ease-in;
    color: $primary-color;

    &:hover {
        color: $primary-color;
        text-decoration: underline;
    }
}
